<template>
  <div class="report" v-if="!showDetail">
    <div class="form-title">
      <el-radio-group v-model="type" @change="changeType">
        <el-radio-button label="1">{{ $t('report__nowMonth') }}</el-radio-button>
        <el-radio-button label="2">{{ $t('report__lastMonth') }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(v, i) in list" :key="i" v-loading="!loaded">
        <div class="box disFlexs">
          <div class="title disFlexc">{{ $t(titleList[i]) }}</div>
          <div class="content disFlexs" :class="{green: parseFloat(v) < 0}">
            <div v-if="!iscolor(i)" class="u_fz--36">{{ v }}</div>
            <div
              class="u_fz--36"
              :class="[{green: parseFloat(v) < 0}, {red: parseFloat(v) > 0}]"
              v-else
            >
              {{ v }}
            </div>
            <div class="show_detail" v-if="isDetail(i)" @click="showDetail = true">
              {{ $t('report__show_detail') }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <Content-detail v-model="showDetail" :nav-title="$t('report__detail_title')" v-else>
    <div class="detail-top">
      <div class="box underline">
        {{ $t('report__withdraw') }}
        <p @click="openShowWithOut">
          {{ list.withdraw }}
        </p>
      </div>
      <div class="box">
        {{ $t('report__win_loss') }}
        <p :class="[{green: parseFloat(list.win_loss) < 0}, {red: parseFloat(list.win_loss) > 0}]">
          {{ list.win_loss }}
        </p>
      </div>
      <div class="box">
        {{ $t('report__platform_fee') }}
        <p>{{ list.platform_fee }}</p>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="company_name" :label="$t('report__table1')" width="180">
      </el-table-column>
      <el-table-column :label="$t('report__table2')">
        <template #default="scope">
          <div
            :class="[
              {green: parseFloat(scope.row.win_loss) < 0},
              {red: parseFloat(scope.row.win_loss) > 0}
            ]"
          >
            {{ formatNumber(scope.row.win_loss) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('report__table3')">
        <template #default="scope">
          <div>
            {{ scope.row.rate }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('report__table4')">
        <template #default="scope">
          <div>
            {{ formatNumber(scope.row.platform_fee) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="page-box">
      <el-pagination layout="prev, pager, next" :total="1000" />
    </div> -->
  </Content-detail>
  <el-dialog v-model="showWithOut" :before-close="() => (showWithOut = false)" custom-class="bonus">
    <el-table :data="tableData2" style="width: 100%">
      <el-table-column prop="company_name" :label="$t('report__with1')" width="180">
        <template #default="scope">
          <div>
            {{ scope.row.customize }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('report__with2')">
        <template #default="scope">
          <div>
            {{ scope.row.userAccount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('report__with3')">
        <template #default="scope">
          <div>
            {{ scope.row.time.slice(0, 10) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('report__with4')">
        <template #default="scope">
          <div>
            {{ scope.row.price }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-align-right withtotal">{{ $t('report__total') }}:{{ sum.pageReward }}</div>
    <div class="text-align-right withtotal">{{ $t('report__allTotal') }}:{{ sum.totalReward }}</div>
    <div class="text-align-right">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="withPages"
        :total="withTotal"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>
  </el-dialog>
  <div class="notice">
    <p><i class="el-icon-warning"></i> {{ $t('report__notice_title') }}</p>
    <template v-if="!showDetail">
      <p>{{ $t('report__notice_p1') }}</p>
      <p>{{ $t('report__notice_p2') }}</p>
    </template>
    <p v-else>1. {{ $t('ui_text_0084') }}</p>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ContentDetail} from '@/common/web';
import {Format} from '@/services';
import {
  IReport,
  IReportList,
  IReportListDetail,
  Report,
  IReportListDetail2,
  IReport2
} from '@/model';
@Options({
  components: {
    ContentDetail
  }
})
export default class Login extends Vue {
  type = '1';
  page = 1;

  //红利页数
  withpage = 1;
  //红利总数据数
  withTotal = 0;
  pageSize = 10;

  loaded = false;
  showWithOut = false;
  sum = {
    totalReward: '0',
    pageReward: '0'
  };
  list: IReportList = {
    active: 0,
    register: 0,
    withdraw: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    platform_fee: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    win_loss: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    net_win_loss: 0,
    topup: 0,
    topupNumber: 0,
    withdrawNumber: 0
  };
  titleList = {
    active: 'report__withdraw',
    register: 'report__register',
    withdraw: 'report__topup',
    // eslint-disable-next-line @typescript-eslint/camelcase
    platform_fee: 'report__platform_fee',
    // eslint-disable-next-line @typescript-eslint/camelcase
    win_loss: 'report__win_loss',
    // eslint-disable-next-line @typescript-eslint/camelcase
    net_win_loss: 'report__net_win_loss',
    topup: 'report__active',
    topupNumber: 'report__topupNumber',
    withdrawNumber: 'report__withdrawNumber'
  };

  tableData?: Array<IReportListDetail> = [];
  tableData2?: Array<IReportListDetail2> = [];
  showDetail = false;

  isDetail(e: string) {
    return e === 'win_loss' || e === 'platform_fee';
  }
  iscolor(e: string) {
    return e === 'win_loss' || e === 'net_win_loss';
  }

  created() {
    this.getData();
  }
  changeType() {
    this.getData();
  }
  withPages(e: number) {
    this.withpage = e;
    this.getWith();
  }
  openShowWithOut() {
    this.showWithOut = true;
    this.getWith();
  }
  /**
   * 红利返水
   */
  async getWith() {
    this.loaded = false;
    const res = await Report.getWith<IReport2>(this.withpage, this.type);
    if (!(res instanceof Error)) {
      const data = res.list;
      console.log(JSON.stringify(data) + 'here');
      this.tableData2 = data;
      this.loaded = true;
      this.sum = {...this.sum, ...res.calculate};
      this.withTotal = res.pagination.totalNumber;
      this.pageSize = res.pagination.pageLimit;
      console.log(res, 'getWith');
    }
  }
  /**
   * 宫格与详情数据
   */
  async getData() {
    this.loaded = false;
    const res = await Report.getReportData<IReport>(this.page, this.type);
    if (!(res instanceof Error)) {
      const data = res.list[0];
      console.log('data: ', data, res);
      this.tableData = data.detail;
      delete data.detail;
      delete data.bet;
      this.list = data;
      this.loaded = true;
    }
  }
  formatNumber(val: number | string) {
    return Format.formatNumber(val, 2);
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.report {
  .box {
    height: 130px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    border-radius: 8px;
    overflow: hidden;
    .title {
      height: 40px;
      background: $bg;
      color: #fff;
      width: 100%;
      font-size: 14px;
    }
    .content {
      flex-grow: 1;
      color: #333;
      font-size: 18px;
      font-weight: bold;
      justify-content: center;
      .show_detail {
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
        color: #666;
        font-weight: 500;
      }
    }
    .green {
      color: #5daf34;
    }
  }
}
.notice {
  line-height: 2;
  font-size: 12px;
  color: #ff0000;
  padding: 0 20px;
}
.detail-top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .box {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px 0;
    &.underline {
      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    p {
      font-weight: bold;
      margin: 0;
      margin-top: 15px;
    }
  }
}
.green {
  color: #5daf34;
}
.red {
  color: red;
}
.u_fz--36 {
  font-size: 36px;
}
.page-box {
  text-align: right;
  padding-top: 20px;
}
.form-title {
  :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
    background-color: $bg;
    border-color: $bg;
  }
}
.withtotal {
  font-size: 14px;
  margin: 15px 10px;
}
</style>

<style lang="scss">
.bonus .el-dialog__headerbtn {
  z-index: 10;
}
</style>
