
import {Options, Vue} from 'vue-class-component';
import {ContentDetail} from '@/common/web';
import {Format} from '@/services';
import {
  IReport,
  IReportList,
  IReportListDetail,
  Report,
  IReportListDetail2,
  IReport2
} from '@/model';
@Options({
  components: {
    ContentDetail
  }
})
export default class Login extends Vue {
  type = '1';
  page = 1;

  //红利页数
  withpage = 1;
  //红利总数据数
  withTotal = 0;
  pageSize = 10;

  loaded = false;
  showWithOut = false;
  sum = {
    totalReward: '0',
    pageReward: '0'
  };
  list: IReportList = {
    active: 0,
    register: 0,
    withdraw: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    platform_fee: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    win_loss: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    net_win_loss: 0,
    topup: 0,
    topupNumber: 0,
    withdrawNumber: 0
  };
  titleList = {
    active: 'report__withdraw',
    register: 'report__register',
    withdraw: 'report__topup',
    // eslint-disable-next-line @typescript-eslint/camelcase
    platform_fee: 'report__platform_fee',
    // eslint-disable-next-line @typescript-eslint/camelcase
    win_loss: 'report__win_loss',
    // eslint-disable-next-line @typescript-eslint/camelcase
    net_win_loss: 'report__net_win_loss',
    topup: 'report__active',
    topupNumber: 'report__topupNumber',
    withdrawNumber: 'report__withdrawNumber'
  };

  tableData?: Array<IReportListDetail> = [];
  tableData2?: Array<IReportListDetail2> = [];
  showDetail = false;

  isDetail(e: string) {
    return e === 'win_loss' || e === 'platform_fee';
  }
  iscolor(e: string) {
    return e === 'win_loss' || e === 'net_win_loss';
  }

  created() {
    this.getData();
  }
  changeType() {
    this.getData();
  }
  withPages(e: number) {
    this.withpage = e;
    this.getWith();
  }
  openShowWithOut() {
    this.showWithOut = true;
    this.getWith();
  }
  /**
   * 红利返水
   */
  async getWith() {
    this.loaded = false;
    const res = await Report.getWith<IReport2>(this.withpage, this.type);
    if (!(res instanceof Error)) {
      const data = res.list;
      console.log(JSON.stringify(data) + 'here');
      this.tableData2 = data;
      this.loaded = true;
      this.sum = {...this.sum, ...res.calculate};
      this.withTotal = res.pagination.totalNumber;
      this.pageSize = res.pagination.pageLimit;
      console.log(res, 'getWith');
    }
  }
  /**
   * 宫格与详情数据
   */
  async getData() {
    this.loaded = false;
    const res = await Report.getReportData<IReport>(this.page, this.type);
    if (!(res instanceof Error)) {
      const data = res.list[0];
      console.log('data: ', data, res);
      this.tableData = data.detail;
      delete data.detail;
      delete data.bet;
      this.list = data;
      this.loaded = true;
    }
  }
  formatNumber(val: number | string) {
    return Format.formatNumber(val, 2);
  }
}
